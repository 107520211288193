
import { defineComponent } from "vue";
import { useMonthlyIncome } from "@/graphql/dashboard/revenue.chart";
import { activeActivity } from "@/plugins/i18n";
import { numberSeparators } from "@/graphql/utils/utils";

export default defineComponent({
  name: "RevenueChart",
  props: ["year"],
  setup(props) {
    return {
      ...useMonthlyIncome(props.year),
      options: {
        plugins: {
          legend: {
            display: true,
            labels: {
              color: "grey",
            },
          },
        },
        responsive: true,
        hover: {
          mode: "index",
        },
        scales: {
          y: {
            ticks: {
              min: 0,
            },
          },
        },
      },
      activeActivity,
      numberSeparators,
    };
  },
  created() {
    this.initChart(this.year);
  },
});
