import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3f9f2ca3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-col-12 p-md-4" }
const _hoisted_2 = { class: "p-col-12 p-md-4" }
const _hoisted_3 = { class: "p-col-12 p-md-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Alert = _resolveComponent("Alert")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Alert, {
        icon: "pi pi-shopping-cart",
        title: _ctx.$t('ticket.sale'),
        value1: 
        _ctx.overviews.ticketOverviews[0]?.count +
        _ctx.overviews.ticketOverviews[2]?.count
      ,
        text1: _ctx.$t('ticket.payed'),
        value2: _ctx.overviews.ticketOverviews[1]?.count,
        text2: _ctx.$tm('ticket.status')[1],
        value3: _ctx.overviews.ticketOverviews[3]?.count,
        text3: _ctx.$tm('ticket.status')[5],
        number: 4
      }, null, 8, ["title", "value1", "text1", "value2", "text2", "value3", "text3"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Alert, {
        icon: "pi pi-users",
        title: _ctx.$t('customer.customers'),
        value1: _ctx.overviews.customersOverview.active,
        text1: _ctx.$t('customer.active'),
        value2: _ctx.overviews.customersOverview.registered,
        text2: _ctx.$t('customer.registered'),
        number: 3
      }, null, 8, ["title", "value1", "text1", "value2", "text2"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Alert, {
        icon: "pi pi-money-bill",
        title: `${_ctx.$t('ticket.revenue')}(${_ctx.activeActivity.currencySymbol})`,
        value1: _ctx.overviews.expenseAmount,
        text1: _ctx.$t('ticket.expenses'),
        value2: _ctx.overviews.income,
        text2: _ctx.$t('ticket.income'),
        number: 3
      }, null, 8, ["title", "value1", "text1", "value2", "text2"])
    ])
  ], 64))
}