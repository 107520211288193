import { MonthlyRevenueOutput, QueryMonthlyIncomeArgs } from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { useLazyQuery } from "@vue/apollo-composable";
import { reactive } from "vue";
import { activeActivity } from "@/plugins/i18n";
import { useI18n } from "vue-i18n";

type RevenueChartData = {
  monthlyIncome: MonthlyRevenueOutput[];
  monthlyExpenses: MonthlyRevenueOutput[];
};

const REVENUE_MONTHLY = gql`
  query RevenueChart($input: MonthlyRevenueInput!) {
    monthlyIncome(input: $input) {
      month
      amount
    }
    monthlyExpenses(input: $input) {
      month
      amount
    }
  }
`;

export const useMonthlyIncome = (year: number) => {
  const { tm } = useI18n();

  const chartData = reactive({
    labels: tm("prime.monthNamesShort") as string[],
    datasets: [
      {
        label: tm("ticket.income"),
        data: Array(12).fill(0),
        borderColor: "#42A5F5",
        borderWidth: 2,
        fill: false,
        tension: 0.4,
      },
      {
        label: tm("ticket.expenses"),
        data: Array(12).fill(0),
        fill: false,
        borderWidth: 2,
        borderColor: "#FFA726",
        tension: 0.4,
      },
    ],
  });

  const { loading, load, onResult } = useLazyQuery<
    RevenueChartData,
    QueryMonthlyIncomeArgs
  >(
    REVENUE_MONTHLY,
    {
      input: {
        activityId: activeActivity.value.id,
        year,
      },
    },
    { fetchPolicy: "no-cache" }
  );
  onResult(({ data }) => {
    if (data) {
      for (let month = 1; month <= 12; month++) {
        chartData.datasets[0].data[month - 1] =
          data.monthlyIncome.find((s) => s.month === month)?.amount || 0;
        chartData.datasets[1].data[month - 1] =
          data.monthlyExpenses.find((s) => s.month === month)?.amount || 0;
      }
    }
  });
  function initChart(year: number) {
    void load(REVENUE_MONTHLY, {
      input: {
        activityId: activeActivity.value.id,
        year,
      },
    });
  }
  return { loading, chartData, initChart };
};
