import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card revenue" }
const _hoisted_2 = { class: "p-mb-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Chart = _resolveComponent("Chart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.$t("dashboard.monthlySalesVolume")), 1),
    _createVNode(_component_Chart, {
      type: "pie",
      id: "ticks-chart",
      data: _ctx.chartData,
      options: _ctx.lightOptions,
      style: {"width":"100%"},
      height: 400
    }, null, 8, ["data", "options"])
  ]))
}