
import { defineComponent } from "vue";
import { useOverviews } from "@/graphql/dashboard/overviews";
import { activeActivity } from "@/plugins/i18n";
import Alert from "@/components/common/Alert.vue";

export default defineComponent({
  name: "Overviews",
  components: { Alert },
  props: ["input"],
  setup(props) {
    return {
      ...useOverviews(props.input),
      activeActivity,
    };
  },
  beforeMount() {
    this.loader();
  },
});
