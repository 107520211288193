import {
  QueryTicketsVolumeArgs,
  TicketOverviewsOutput,
  TicketsVolumeInput,
} from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { useLazyQuery } from "@vue/apollo-composable";
import { reactive } from "vue";
import { useI18n } from "vue-i18n";
import { statusColors } from "@/components/rapport/printer";
import { TicketStatusEnum } from "../ticket/ticket";

type TicketsVolumeData = {
  ticketsVolume: TicketOverviewsOutput[];
};

const TICKETS_VOLUME = gql`
  query TicketsVolume($input: TicketsVolumeInput!) {
    ticketsVolume(input: $input) {
      status
      count
    }
  }
`;

export const useTicketsVolume = (input: TicketsVolumeInput) => {
  const { tm } = useI18n();
  const colors = statusColors.filter((_, index) => ![3, 4].includes(index));
  const chartData = reactive({
    labels: (tm("ticket.status") as string[]).filter(
      (_, index) => ![3, 4].includes(index)
    ),
    datasets: [
      {
        data: [],
        backgroundColor: colors,
        hoverBackgroundColor: colors,
        borderWidth: 0,
      },
    ],
  });

  const { loading, load, onResult } = useLazyQuery<
    TicketsVolumeData,
    QueryTicketsVolumeArgs
  >(TICKETS_VOLUME, { input }, { fetchPolicy: "no-cache" });
  onResult(({ data }) => {
    chartData.datasets[0].data = Array(5).fill(0);
    data.ticketsVolume.forEach((tic) => {
      tic.count = +tic.count;
      switch (tic.status as TicketStatusEnum) {
        case TicketStatusEnum.PAYED:
        case TicketStatusEnum.CREDIT_PAYED:
          chartData.datasets[0].data[0] += tic.count;
          break;
        case TicketStatusEnum.CREDIT:
          chartData.datasets[0].data[1] += tic.count;
          break;
        case TicketStatusEnum.WAITING:
          chartData.datasets[0].data[2] += tic.count;
          break;
        case TicketStatusEnum.CANCELLED:
          chartData.datasets[0].data[3] += tic.count;
          break;
        case TicketStatusEnum.OFFER:
          chartData.datasets[0].data[4] += tic.count;
          break;
      }
    });
  });
  function initChart() {
    void load();
  }
  return { loading, chartData, initChart, input };
};
