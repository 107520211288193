
import { defineComponent } from "vue";
import Overviews from "./Overviews.vue";
import TicketChart from "./TicketsChart.vue";
import RevenueChart from "./RevenueChart.vue";

export default defineComponent({
  name: "AdminIndex",
  props: ["input"],
  components: { RevenueChart, TicketChart, Overviews },
  setup() {
    return {};
  },
});
