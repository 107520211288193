import {
  CustomersOverviewOutput,
  QueryTicketOverviewsArgs,
  TicketOverviewsOutput,
  TicketsVolumeInput,
} from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { useLazyQuery, useResult } from "@vue/apollo-composable";

type OverviewsData = {
  ticketOverviews: TicketOverviewsOutput[];
  customersOverview: CustomersOverviewOutput;
  expenseAmount: number;
  income: number;
};

const OVERVIEWS = gql`
  query TicketOverviews($input: TicketsVolumeInput!) {
    ticketOverviews(input: $input) {
      status
      count
    }
    customersOverview(input: $input) {
      registered
      active
    }
    expenseAmount(input: $input)
    income(input: $input)
  }
`;

export const useOverviews = (input: TicketsVolumeInput) => {
  const defaultOverViews: OverviewsData = {
    ticketOverviews: [0, 1, 3, 5].map((status) => ({
      count: 0,
      status,
    })),
    customersOverview: {
      registered: 0,
      active: 0,
    },
    expenseAmount: 0,
    income: 0,
  };
  const { loading, result, load } = useLazyQuery<
    OverviewsData,
    QueryTicketOverviewsArgs
  >(OVERVIEWS, { input }, { fetchPolicy: "no-cache" });
  const overviews = useResult<OverviewsData, OverviewsData, OverviewsData>(
    result,
    defaultOverViews,
    (res) => ({
      ...res,
      ticketOverviews: defaultOverViews.ticketOverviews.map((val) => ({
        count: 0,
        ...res.ticketOverviews.find((tic) => tic.status == val.status),
      })),
    })
  );
  function loader() {
    void load();
  }
  return { overviews, loading, loader, result };
};
