import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-col-12 p-xl-6" }
const _hoisted_2 = { class: "p-col-12 p-xl-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Overviews = _resolveComponent("Overviews")!
  const _component_TicketChart = _resolveComponent("TicketChart")!
  const _component_RevenueChart = _resolveComponent("RevenueChart")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Overviews, { input: _ctx.input }, null, 8, ["input"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_TicketChart, { input: _ctx.input }, null, 8, ["input"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_RevenueChart, {
        year: _ctx.input.year
      }, null, 8, ["year"])
    ])
  ], 64))
}