
import { defineComponent } from "vue";
import { useTicketsVolume } from "@/graphql/dashboard/tickets.volume";

export default defineComponent({
  name: "TicketChart",
  props: ["input"],
  setup(props) {
    return {
      lightOptions: {
        plugins: {
          legend: {
            display: true,
            labels: {
              color: "grey",
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      },
      ...useTicketsVolume(props.input),
    };
  },
  created() {
    this.initChart();
  },
});
